import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NewContactBE } from 'src/app/classes/NewContactBE';

import { DatalexClient, IContactAddressBE, IContactBE, ICountryBE, IPostalAddressBE, IUserLimitedBE, IContactPhoneBE } from '@datalex-software-as/datalex-client';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { AddressTypes } from 'src/app/util/AddressTypeUtil';
import { ContactSectorIdEnum, ContactSectorsArray } from 'src/app/util/ContactSectorUtil';
import { PhoneNumberTypeIdEnum, PhoneNumberTypesArray } from 'src/app/util/PhoneNumberTypeUtil';
import { EmptyGuid } from 'src/app/util/RandomId';
import { CompareCode } from 'src/app/util/SortCompare';
import { CountryService } from 'src/app/services/country.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { IgxDialogComponent, IgxInputGroupComponent, IgxInputDirective, IgxLabelDirective, IgxSimpleComboComponent, IgxDatePickerComponent, IgxButtonDirective, IgxRippleDirective, ISimpleComboSelectionChangingEventArgs } from '@infragistics/igniteui-angular';
import { NgModel, FormsModule } from '@angular/forms';
import { DlxAddressInputComponent } from '../../../../UI/dlx-address-input/dlx-address-input.component';
import { EmailValidatorDirective } from '../../../../../directives/emailvalidator.directive';
import { BirthDayNumberValidatorDirective } from '../../../../../directives/birth-day-number-validator.directive';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-personal-desktop-contact',
  templateUrl: './personal-desktop-contact.component.html',
  styleUrls: ['./personal-desktop-contact.component.scss'],
  standalone: true,
  imports: [NgIf, IgxInputGroupComponent, FormsModule, IgxInputDirective, IgxLabelDirective, IgxSimpleComboComponent, BirthDayNumberValidatorDirective, IgxDatePickerComponent, EmailValidatorDirective, DlxAddressInputComponent, IgxButtonDirective, IgxRippleDirective, IgxDialogComponent]
})
export class PersonalDesktopContactComponent implements OnInit {

  constructor(
    private dlxClient: DatalexClient,
    private sys: SystemCacheService,
    private countryData: CountryService,
    public combo: ComboSelectFunctionsService) {

  }
  @Output() save: EventEmitter<IContactBE> = new EventEmitter();
  @Output() notifySectorChange: EventEmitter<string> = new EventEmitter();
  @Input() contactSectorId!: string;
  @ViewChild('nationalIdentityNumberCtrl') nationalIdentityNumberCtrl!: NgModel;
  @ViewChild('emailAddressCtrl') emailAddressCtrl!: NgModel;
  @ViewChild('invoiceEmailAddressCtrl') invoiceEmailAddressCtrl!: NgModel;

  @ViewChild('nationalNumberErrorDialog') public nationalNumberErrorDialog!: IgxDialogComponent;


  genders = [
    {
      code: "M",
      label: "Mann",
      value: true,
      id: 'gender_m'
    },
    {
      code: "K",
      label: "Kvinne",
      value: false,
      id: "gender_k"
    },
    {
      code: "IO",
      label: "Ikke oppgitt",
      value: null,
      id: 'gender_io'
    }
  ]

  addressTypes = AddressTypes;

  contactSectorType!: string;
  contactNumber: number = 0;
  contactSectors = ContactSectorsArray.filter(s => s.Id !== ContactSectorIdEnum.M);
  countryCode: string | null = "NO";

  firstName!: string;
  lastName!: string;
  name2!: string | null;
  psuedo!: string | null;
  occupation!: string | null;
  title!: string | null;
  initials!: string | null;
  country!: ICountryBE;
  nationalIdentityNumber!: string | null;
  dateOfBirth!: string | null;
  organizationNumber!: string | null;
  contactGender: boolean | null = null;

  emailAddress!: string | null
  webAddress!: string | null
  emailAddressInvoice!: string | null

  vatObligated: boolean = this.countryCode === "NO" ? true : false;
  sendInvoiceAsEmail: boolean | null = null;
  sendInvoiceAsDigipost: boolean | null = null;

  zipCode!: string | null;
  postArea!: string | null;

  checkValue!: boolean

  postAddressObj!: IContactAddressBE;
  setPostAddressObj(obj: IContactAddressBE) {


    this.postAddressObj = obj;
  }

  visitAddressObj!: IContactAddressBE;
  setVisitAddressObj(obj: IContactAddressBE) {
    this.visitAddressObj = obj;
  }

  invoiceAddressObj!: IContactAddressBE;
  setInvoiceAddressObj(obj: IContactAddressBE) {
    this.invoiceAddressObj = obj;
  }

  registeredAddressObj!: IContactAddressBE;
  setRegisteredAddressObj(obj: IContactAddressBE) {
    this.registeredAddressObj = obj;
  }

  countries = this.countryData.allCountries

  onCountryChange(event: string) {

    this.dlxClient.GetCountryByCode(event).subscribe({
      next: (country) => {
        this.countryCode = event;
        this.country = country;
        if (country.Code.toLocaleUpperCase() === "NO") {
          this.vatObligated = true;
        }
      }
    })
  }
  onCountryReset() {
    this.countryCode = null;
  }

  allEmployees!: IUserLimitedBE[];
  clientResponsible!: IUserLimitedBE | null;

  onClientResponsibleChange(e: string) {


  }

  phoneNumberTypes = PhoneNumberTypesArray;
  currentPhoneTypeId = PhoneNumberTypeIdEnum.Mobile;
  currentPhoneNumber!: string | null;
  phonesNumbers: IContactPhoneBE[] = [];

  onPhoneInputChange(e: any) {
    switch (this.currentPhoneTypeId) {
      case PhoneNumberTypeIdEnum.Private:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Private))
        break;
      case PhoneNumberTypeIdEnum.Mobile:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Mobile))
        break;
      case PhoneNumberTypeIdEnum.Work:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Work))
        break;
      case PhoneNumberTypeIdEnum.Div:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Div))
        break;
      case PhoneNumberTypeIdEnum.Fax:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Fax))
        break;
      case PhoneNumberTypeIdEnum.Vacation:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Vacation))
        break;
      default:
        throw new Error('Uknown phonenumber type')
    }
  }

  setPhoneNumber(index: number) {
    this.phonesNumbers[index].Number = this.currentPhoneNumber ?? "";
  }

  onPhoneTypeChange(e: string) {
    if (!e) return
    const existingNumber = this.phonesNumbers.find(el => el.PhoneTypeId === e)!;
    this.currentPhoneNumber = existingNumber.Number === "" || existingNumber.Number === null ? null : existingNumber.Number;
  }

  ngOnInit(): void {




    this.contactSectorType = ContactSectorsArray.filter(s => s.Id === this.contactSectorId)[0].Name;

    if (this.contactSectorId === ContactSectorIdEnum.S) {
      this.contactSectorType = "Selvstendig næringsdrivende"
    }

    try {
      this.allEmployees = this.sys.allEmployees.sort(CompareCode);
    } catch (err) {
      this.sys.isReady.subscribe((sys) => {
        this.allEmployees = sys.allEmployees.sort(CompareCode);
      })
    }
    this.dlxClient.GetCountryByCode('no').subscribe({
      next: (country) => {
        this.country = country;
        if (country.Code.toLocaleUpperCase() !== "NO") {
          this.vatObligated = false;
        }
      }
    })

    this.phoneNumberTypes.forEach(type => {
      this.phonesNumbers.push({
        IsNew: true,
        IsDeleted: false,
        Id: EmptyGuid,
        ContactId: EmptyGuid,
        PhoneTypeId: type.Id,
        PhoneType: type.Name,
        Number: "",
        CountryCode: "",
        Extension: null,
        Timestamp: []
      })
    })

  }

  onSectorReset() {
  }

  onSectorChange(e: string) {

    this.notifySectorChange.emit(e)
    if (e === ContactSectorIdEnum.P) {
      this.contactSectorType = "Person"
      return
    }
    if (e === ContactSectorIdEnum.S) {
      this.contactSectorType = "Selvstendig næringsdrivende"
      return
    }
  }

  onSectorSelectionChanging(e: ISimpleComboSelectionChangingEventArgs): void {


    const { newSelection, newValue, oldValue } = e;
    const id = newSelection ? newSelection.Id : null;

    if (newValue === undefined) {
      this.contactSectorId = oldValue;
      e.cancel = true;

      return;
    }

    if (this.contactSectorId !== id) {
      this.contactSectorId = id;

      if (id === 'P') {
        this.contactSectorType = 'Person';
      } else if (id === 'S') {
        this.contactSectorType = 'Selvstendig næringsdrivende';
      } else {
        this.contactSectorType = this.contactSectors.find(sec => sec.Id === id)?.Name || '';
      }

      this.notifySectorChange.emit(id);

    } else {
      e.cancel = true;
    }
  }


  addressRadioChange(event: Event, radio: HTMLInputElement) {
    this._activeRadio = radio.id;
  }

  _activeRadio = "post"
  postalAddress!: IPostalAddressBE;

  postAddress!: string;
  visitAddress!: string;
  regAddress!: string;
  invoiceAddress!: string;


  onSave() {
    const numbersToSave = this.phonesNumbers.filter(num => num.Number)
    const addressesToSave = [this.postAddressObj, this.visitAddressObj, this.registeredAddressObj, this.invoiceAddressObj]
      .filter(adr => adr?.Address && adr?.PostalAddress !== null)


    let contactBe = new NewContactBE({
      FirstName: this.firstName,
      LastName: this.lastName,
      Name: null,
      ContactSectorId: this.contactSectorId,
      Country: this.country
    })

    contactBe.Name2 = this.name2 ?? null;
    contactBe.PseudoName = this.psuedo ?? null;
    contactBe.Occupation = this.occupation ?? null;
    contactBe.Title = this.title ?? null;
    contactBe.ShortForm = this.initials ?? null;
    contactBe.Country = this.country;
    contactBe.NationalIdentityNumber = this.nationalIdentityNumber ?? null;
    contactBe.OrganizationNumber = this.organizationNumber ?? null;
    contactBe.DateOfBirth = this.dateOfBirth ? toLocalIsoString(this.dateOfBirth) : null;
    contactBe.Phones = numbersToSave;
    contactBe.Addresses = addressesToSave;
    contactBe.VatObligated = this.vatObligated;
    contactBe.DigipostInvoice = this.sendInvoiceAsDigipost;
    contactBe.EmailInvoice = this.sendInvoiceAsEmail;
    contactBe.Gender = this.contactGender;
    contactBe.EmailAddress = this.emailAddress ?? null
    contactBe.WebAddress = this.webAddress ?? null
    contactBe.EmailAddressInvoice = this.emailAddressInvoice ?? null
    contactBe.ResponsibleUserId = this.clientResponsible?.Id ?? null;
    contactBe.ClientResponsible = this.clientResponsible ?? null;

    this.save.emit(contactBe);
  }
  iso = toLocalIsoString;
  log(e: any) {


  }

  inputBlurred = false;

  validateAndAct() {
    if (this.nationalIdentityNumberCtrl.invalid) {
      this.nationalNumberErrorDialog.open();
    } else {
      this.onSave();
    }
  }

  preventEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  showEmailErrorMessage: boolean = false;
  showInvoiceEmailErrorMessage: boolean = false;

  hideErrorMessage(inputName: string) {
    if (inputName === 'emailAddress') {
      this.showEmailErrorMessage = false;
    } else if (inputName === 'emailAddressInvoice') {
      this.showInvoiceEmailErrorMessage = false;
    }
  }

  showErrorMessageOnBlur(inputName: string) {
    if (inputName === 'emailAddress' && this.emailAddressCtrl.invalid) {
      this.showEmailErrorMessage = true;
    } else if (inputName === 'emailAddressInvoice' && this.invoiceEmailAddressCtrl.invalid) {
      this.showInvoiceEmailErrorMessage = true;
    }
  }
}
